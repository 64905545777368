
import ajaxReq from './ajaxReq'

// 注册 
export const Register = data => {
  return ajaxReq('/Login/Register', data, 'POST')
}

// 获取验证码
export const CreateAndSendCaptcha = data => {
  return ajaxReq('/Captcha/CreateAndSendCaptcha', data, 'POST')
}

// 登录
export const LoginByPassword = data => {
  return ajaxReq('/Login/LoginByPassword', data, 'POST')
}

//重置密码 
export const ResetPassword = data => {
  return ajaxReq('/Login/ResetPassword', data, 'POST')
}

// 上传文件 UploadDicom/UpLoadFile
export const UpLoadFile = data => {
  return ajaxReq('/Upload/UpLoadFile', data, 'POST')
}

// Api/Logic/GetQCFiles 质控规范
export const GetQCFiles = data => {
  return ajaxReq('/Logic/GetQCFiles', data, 'GET')
} 
// 简讯 getZXInfo
export const getZXInfo = data => {
  return ajaxReq('/Logic/getZXInfo', data, 'GET')
} 

// // 视频接口 GetQCVideos
// export const GetQCVideos = data => {
//   return ajaxReq('/Logic/GetQCVideos', data, 'GET')
// }

// 获取留言记录
export const GetQCVideoMessage = data => {
  return ajaxReq('/Logic/GetQCVideoMessage', data, 'GET')
}

// 保存留言
export const SaveQCVideoMessage = data => {
  return ajaxReq('/Logic/SaveQCVideoMessage', data, 'POST')
}
// 质控指标获取分类 Api/Logic/getQCVideoMains
export const getQCVideoMains = data => {
  return ajaxReq('/Logic/getQCVideoMains', data, 'GET')
}
// 指控指标分类获取详情 Api/Logic/GetQCVideos？QCVideoMainId=123 
export const GetQCVideos = data => {
  return ajaxReq('/Logic/GetQCVideos', data, 'GET')
}
// 根据级别获取会议列表 getnews type：  1国家   2亚专业组  3地市级  4省级
export const getnews = data => {
  return ajaxReq('/Logic/getnews', data, 'GET')
}
// 获取视频详情 getQCVideoDetail
export const getQCVideoDetail = data => {
  return ajaxReq('/Logic/getQCVideoDetail', data, 'GET')
}
// 观看视频计数 setQCVideoCount
export const setQCVideoCount = data => {
  return ajaxReq('/Logic/setQCVideoCount', data, 'POST')
}



















